import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";

import {
  DataGrid,
  ruRU,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";






const CustomToolbar = ({ value, onChange }) => {

  

  return (
    <GridToolbarContainer>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </Grid>
        <Grid
          item
          xs
          container
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Grid item style={{paddingRight:'10px' }}>
          </Grid>
          <Grid item>
            <TextField
              variant="standard"
              value={value}
              onChange={onChange}
              placeholder="Поиск…"
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export const UserStatTable = ({ data, columns }) => {
  const [searchText, setSearchText] = useState("");
  const [currentRows, setCurrentRows] = useState(data);

  //clear()
  

  
  useEffect(() => {  
    setCurrentRows(data);
  }, [data]);


  const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };
   // const { redirect } = useRedirect();
  
    //const onChartClick = async (e) => {
    //  const chartId = e.row.id;
    //  redirect(RouteNames.CHART_DETAIL, { chartId });
    //};

    const requestSearch = (searchValue) => {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field]?.toString());
        });
      });
      setCurrentRows(filteredRows);
    };    

    return (

  
      <Paper  style={{ height: "100%", width: "100%",paddingTop:'20px' }}>
        <DataGrid
          columns={columns}
          rows={currentRows}
          components={{ Toolbar: CustomToolbar }}

          density={"standart"}
          autoHeight={true}
          //headerHeight={123}
          localeText={
            ruRU?.props?.MuiDataGrid?.localeText ||
            ruRU?.components?.MuiDataGrid?.defaultProps?.localeText
          }
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
            },
          }}
          disableSelectionOnClick
          disableColumnMenu
          cell--textRight
        />
      </Paper>
    
    );
  };