import { EQPAPI } from "../../api/api";


const SET_REGISTRATION_STAT = 'statReducer/SET_REGISTRATION_STAT';
const SET_QUERY_STAT = 'statReducer/SET_QUERY_STAT';
const SET_LOADER = 'statReducer/SET_LOADER';
const SET_ERROR = "statReducer/ERROR";


const initialState = {
    registrasion_stat: null,
    query_stat: null,
    error: null,
    loader: false,
}

export const statReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTRATION_STAT:
            return {
                ...state,
                registrasion_stat: action.registrasion_stat
            }
        case SET_QUERY_STAT:
            return {
                ...state,
                query_stat: action.query_stat
            }    
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }              
        default:
            return state;
    }
}


export const getRegistrationStat = state => state.statReducer.registrasion_stat
export const getQueryStat = state => state.statReducer.query_stat
export const getError = state => state.statReducer.error
export const getStatLoader = state => state.statReducer.loader

export const setRegistrationStat  = (registrasion_stat) => ({type: SET_REGISTRATION_STAT, registrasion_stat})
export const setQueryStat = (query_stat) => ({type: SET_QUERY_STAT, query_stat})
export const setStatLoader = (loader) => ({type: SET_LOADER, loader})
export const setError = (error) => ({type: SET_ERROR, error})

export const loadRegistrationStat = (filter) => async dispatch => {
    dispatch(setStatLoader(true))
    try {
        const response = await EQPAPI.getStatRegistration(filter)
        
        if (response.status === 200 ) {
            const result = response.data
            dispatch(setRegistrationStat(result))
            dispatch(setStatLoader(false))
        }
    }
    catch (error) {
        dispatch(setStatLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadQueryStat = (filter) => async dispatch => {
    dispatch(setStatLoader(true))
    try {
        const response = await EQPAPI.getStatQuery(filter)
        
        if (response.status === 200 ) {
            const result = response.data
            dispatch(setQueryStat(result))
            dispatch(setStatLoader(false))
        }
    }
    catch (error) {
        dispatch(setStatLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}
