import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import  Loader  from '../Loader/Loader.jsx';
import Container from '@mui/material/Container';
import Paper from "@material-ui/core/Paper";
import {useQuery} from "../../hooks/useQuery.js";
import {useRedirect} from "../../hooks/useRedirect.js";
import DatePicker from "../DatePicker/DatePicker";
import Button from "@material-ui/core/Button";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {getError, getQueryStat,setQueryStat, getStatLoader, loadQueryStat} from "../../redux/reducers/stat-reducer";
import { PieChart, Pie, Sector, Cell} from 'recharts';
import {GridCellExpand} from "../GridCellExpand/GridCellExpand.jsx";
import { UserStatTable } from "../UserStatTable/UserStatTable";

const useStyles = makeStyles((theme) => ({

  datePickerPanel: {
    margin: "10px 0 0 10px",
  },
  datePicker: {
    width:'250px', 
    lineHeight:'40px',
    float:'left'
  },

  button: {
    color: '#054C74',
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#eeeeee',
        color: '#054C74',
      },        
  },
  PieChartStyle:{
    float:'left',display:'flex', height:'200px', alignItems:'center', paddingLeft:'20px'
  },
  PieChartLegend: {
    fontSize:'30px', lineHeight:'10px', fontWeight:'bold'
  },
  PieChartLegendTotal: {
    fontSize:'40px', lineHeight:'0px', fontWeight:'bold'
  },  

}))


export default function StatisticsQuery() {

    const query = useQuery()
    const {replace} = useRedirect()
    const classes = useStyles()
    const dispatch = useDispatch();

    const [refresh, setRefresh] = useState(false)
    const [filter, setFilter] = useState({startTime:null, endTime:null});
    const [queryStatDiagramData, setQueryStatDiagramData] = useState(null)
    const COLORS = ['#0084AE', '#AE0054', '#007B31'];

    const query_stat  = useSelector(getQueryStat);    
    const error  = useSelector(getError);    
    const statLoader  = useSelector(getStatLoader);    

  useEffect(() => {

  }, []);        

  const setFilterValue = ( key, value) => {
    const nextFilter = {...filter};
    nextFilter[key] = value
    
    setFilter(nextFilter)
  }  
  useEffect(() => {
    if (query_stat == null)
      dispatch(loadQueryStat(filter))

  }, []);      

  useEffect(() => {
    if (query_stat != null)
      genUserStatDiagramData()
    
    
  }, [query_stat]); 

  useEffect(() => {
    
    if (refresh == true) {
        dispatch(setQueryStat(null))
        dispatch(loadQueryStat(filter))
        setRefresh(false)
    }
    

  }, [refresh]);        

  
  const genUserStatDiagramData = () => {
    const pieData = [
        {
          name:"Простой",
          value: query_stat?.filter(qItem=>{return (qItem.sattr == 'simple')})?.length
        },
        {
          name:"Расширенный",
          value: query_stat?.filter(qItem=>{return (qItem.sattr == 'advanced')})?.length
        },
        {
          name:"Карточка",
          value: query_stat?.filter(qItem=>{return (qItem.sattr == 'card')})?.length
        }        
      ]
  
      setQueryStatDiagramData(pieData)
  }
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {((percent * 100).toFixed(0)>0)?`${(percent * 100).toFixed(0)}%`:null}
      </text>
    );
  };

  const genUserStatColumns = () => {
    let userStatColumns = [
        { 
        field: "querydate", 
        headerName: "Дата", 
        minWidth: 100, flex: 1, 
        renderCell: RenderUserStatLink
        }, 
        { 
          field: "user", 
          headerName: "Пользователь", 
          minWidth: 100, flex: 1, 
          renderCell: RenderUserCardLink
        },         
        { 
          field: "sattr", 
          headerName: "Тип", 
          width: 100, flex: 1, 
          renderCell:RenderUserStatLink, 
        }, 
        { 
            field: "name", 
            headerName: "Запрос", 
            width: 100, flex: 2, 
            renderCell:RenderUserStatLink, 
        },                  
        ]

    return userStatColumns
  }   
  const RenderUserStatLink = (params) => {
        
    return (
      <GridCellExpand
          value={params.value ? params.value.toString() : ''}
          href = {params.row?.link}
          width={params.colDef.computedWidth}
      />
    )
  }  
  const RenderUserCardLink = (params) => {
        
    return (
      <GridCellExpand
          value={params.value ? params.value.toString() : ''}
          href = {(params.row?.userlink)?params.row?.userlink:'#'}
          width={params.colDef.computedWidth}
      />
    )
  }  

  const genUserStatRows = () => {
    let rowData = []
    query_stat?.forEach( function callback (statItem) {
        let aname = ''
        let alink = '/eqpsearch/advanced/'
        let anamefirst = true
        if (statItem.aname !='') {
          if (!anamefirst) {
            alink +='&'
            aname += ', '             
          } else
            alink +='?'
          aname += statItem.aname
          alink += 'aname='+statItem.aname
          anamefirst = false
        }
        if (statItem.ashifr !='') {
           if (!anamefirst){
              alink +='&'
              aname += ', '
            } else
            alink +='?'
           alink += 'ashifr='+statItem.ashifr

           aname += statItem?.ashifr
           anamefirst = false
        }
        if (statItem.aok) {
          if (!anamefirst){
            alink +='&'
            aname += ', '
          } else
            alink +='?'
          aname += statItem?.okinfo?.NAME
          alink += 'aok='+statItem.aok+'&aokname='+statItem?.okinfo?.NAME
          anamefirst = false
        }else {
          if (!anamefirst){
            alink +='&'
          } else
            alink +='?'
          alink += 'aok=any&aokname=Любой'
        }

        if (statItem.acompany) {
          if (!anamefirst){
            alink +='&'
            aname += ', '
          } else
            alink +='?'
          
          aname += statItem?.companyinfo?.NAME
          alink += 'acompany='+statItem.acompany
          anamefirst = false
        } else {
          if (!anamefirst){
            alink +='&'
          } else
            alink +='?'
          alink += 'acompany=any'
        }



        if (statItem.aregion) {
          if (!anamefirst){
            alink +='&'
            aname += ', '
          } else
            alink +='?'
          aname += statItem?.regioninfo?.NAME
          alink += 'aregion='+statItem.aregion
          anamefirst = false
        } else {
          if (!anamefirst){
            alink +='&'
          } else
            alink +='?'
          alink += 'aregion=any'
        }


        let tmpRow = {
          id: statItem.id,
          user: (statItem.user)?statItem.userinfo.first_name+' '+statItem.userinfo.last_name:'Гость',
          sattr:(statItem.sattr == 'card')?'Карточка':(statItem.sattr == 'simple')?'Простой':(statItem.sattr == 'advanced')?'Расширенный':null,
          name:(statItem.eq)?statItem.eqinfo.NAME+' '+statItem.eqinfo.SHIFR:(statItem.qtext!='')?statItem.qtext:aname,
          querydate:statItem.querydate.slice(0,19).replace('T', ' '),
          link:(statItem.sattr == 'card')?`/eq/${statItem.eqinfo.ID}`:(statItem.sattr == 'simple')?`/eqpsearch/?query=${statItem.qtext}`:(statItem.sattr == 'advanced')?alink:'',
          userlink:(statItem?.user)?'/operator/?tab=Users&id='+statItem.user:null
        }
        rowData.push(tmpRow)
    })
    
    return rowData

}      
    return (
      <div >
        <div className={classes.datePickerPanel}>
          <div className={classes.datePicker}>
            {"C "}
            <DatePicker
              time={filter.startTime}
              handleChange={(value) => setFilterValue('startTime', value) }
            />
          </div>
          <div className={classes.datePicker}>
            {"ПО "}
            <DatePicker 
              time={filter.endTime} 
              handleChange={(value) => setFilterValue('endTime', value) }
            />
          </div>
          <div className={classes.datePicker}>
            <Button variant="contained" 
              //disabled={stepLoader}  
              size={'sdmall'} 
              onClick={()=>setRefresh(true)}  
              className={classes.button}>
              Построить отчет
            </Button>
          </div>

        </div>       
        {(query_stat)?
        <div>
          <div style={{clear:'both', width:'100%', height:'200px', padding:'10px', display:'flex'}}>
            <div>          
              <div style={{float:'left', display:'flex'}}>

                  <PieChart width={200} height={200} >
                      <Pie
                        data={queryStatDiagramData}
                        cx={100}
                        cy={100}
                        innerRadius={50}
                        outerRadius={90}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={renderCustomizedLabel}
                        labelLine={false}
                      >
                        {queryStatDiagramData?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        
                      </Pie>
                  </PieChart>
                  <div className={classes.PieChartStyle}>
                    <div style={{textAlign:'left'}}>
                      <p className={classes.PieChartLegend} style={{color:COLORS[0]}}>{(queryStatDiagramData?.length>0)?queryStatDiagramData[0]?.value:'0'} {(queryStatDiagramData?.length>0)?queryStatDiagramData[0]?.name:'0'}</p>
                      <p className={classes.PieChartLegend} style={{color:COLORS[1]}}>{(queryStatDiagramData?.length>0)?queryStatDiagramData[1].value:'0'} {(queryStatDiagramData?.length>0)?queryStatDiagramData[1]?.name:'0'}</p>
                      <p className={classes.PieChartLegend} style={{color:COLORS[2]}}>{(queryStatDiagramData?.length>0)?queryStatDiagramData[2].value:'0'} {(queryStatDiagramData?.length>0)?queryStatDiagramData[2]?.name:'0'}</p>
                      <p className={classes.PieChartLegendTotal}>{(queryStatDiagramData?.length>0)?(queryStatDiagramData[0].value+queryStatDiagramData[1].value+queryStatDiagramData[2].value):'0'} Всего</p>
                    </div>
                  </div>
              </div>

            </div>
          </div>
        
        <div style={{clear:'both'}}>
        <UserStatTable style={{height:'auto'}}  data={genUserStatRows()} columns={genUserStatColumns()} />
        </div>
        </div>          
        :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table', clear:'both'}}><Loader/></div>         }   

      </div>
    )

}